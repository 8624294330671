import { atom } from "jotai";

import { CustomerConfigResponseSchema } from "@/libs/client";

// キャラクターが喋っているかどうかのアトム
export const isCharacterSpeakingAtom = atom<boolean>(false);

// 設定されている音声の言語
export const languageAtom = atom<string>("ja");

// 音声入力が許可されているかどうかのアトム
export const isVoiceInputAllowedAtom = atom<boolean>(false);

// 画面の中央の位置を表すアトム
export const centerPositionAtom = atom<{ x: number; y: number }>({
  x: 0,
  y: 0,
});

// レコキャラクターの動きのアトム
export const characterMotionAtom = atom<
  "normal" | "move" | "attention" | "happy" | null
>(null);

export const customerConfigAtom = atom<CustomerConfigResponseSchema>({
  customerCode: "",
  origins: [],
  commands: [],
});

export const customerCodeAtom = atom<string>("");
