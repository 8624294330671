import { useCallback, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsArrowRightCircle } from "react-icons/bs";
import styled, { keyframes } from "styled-components";

import { Front, SalesMessage } from "@/commands/RecoSalesCommand/schema";
import { RecoCharacter } from "@/components/atoms/RecoCharacter";
import { IconButtonLinks } from "@/components/molecules/IconButtonLinks";

declare global {
  interface Window {
    gtag?: (type: string, eventName: string, eventParams: unknown) => void;
    ga?: any;
  }
}

type Props = {
  front: Front;
  sales_messages: SalesMessage[];
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
`;

const BubbleBox = styled.div`
  position: absolute;
  bottom: 10px;
  right: 165px;
  width: 285px;
  height: auto;
  background: transparent;
  animation: ${fadeIn} 0.6s ease;

  @media (max-width: 768px) {
    bottom: 0;
    right: 0;
    width: 100vw;
    height: auto;
    border: none;
    background: transparent;
    padding: 0;

    &:after {
      display: none;
    }
  }
`;

const BubbleBoxInner = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background: #f7fafc;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: none;
  padding: 0;
  color: #16566f;
  transition: background-color 0.3s ease;

  &:after {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    border-color: rgba(247, 250, 252, 0);
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-left-width: 31px;
    border-right-width: 31px;
    margin-top: -10px;
    border-left-color: #f7fafc;
    left: 100%;
    top: 50%;
    transition: border-left-color 0.3s ease;
  }

  &:hover {
    color: #ffffff;
    background-color: #16566f;

    &:after {
      border-left-color: #16566f;
    }
  }

  @media (max-width: 768px) {
    border-radius: 0;
    box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.1);
    padding: 0;
    border: none;

    &:after {
      display: none;
    }
  }
`;

const MesasgeContainer = styled.div`
  padding: 16px;

  @media (max-width: 768px) {
    padding: 3px 5px;
  }
`;

const Message = styled("p").withConfig({
  shouldForwardProp: (prop) => !["isHovered"].includes(prop),
})<{ isHovered?: boolean }>`
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 18px;
  }
`;

const Caption = styled("p").withConfig({
  shouldForwardProp: (prop) => !["isHovered"].includes(prop),
})<{ isHovered?: boolean }>`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ActionLabel = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding-block: 10px;
  padding-inline: 3px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(64.02deg, #21a8d2 15.91%, #21dbd2 83.15%);
`;

const ArrowButton = styled("span").withConfig({
  shouldForwardProp: (prop) => !["isHovered"].includes(prop),
})<{ isHovered?: boolean }>`
  background: transparent;
  border: none;
  margin-left: 0.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  transform: ${() => (props) =>
    props.isHovered ? "translateX(0.8rem)" : "translateX(0.5rem)"};
`;

const CharaContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 57px;
    height: 57px;
  }
`;

const CharaButton = styled.button<{ $isOpen: boolean }>`
  position: absolute;
  bottom: ${(props) => (props.$isOpen ? "10px" : "-40px")};
  right: 15px;
  width: 100px;
  height: 100px;
  background: transparent;
  border: none;
  outline: none;
  transition: bottom 0.3s ease;

  @media (max-width: 768px) {
    bottom: ${(props) => (props.$isOpen ? "0" : "-20px")};
    right: 3px;
    width: 57px;
    height: 57px;
  }
`;

const CharaBackground = styled.div`
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 65.23%, #d0f6fe 100%);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -30px;
  right: 0;
  width: 25px;
  height: 25px;
  background: transparent;
  border: none;
  z-index: 9999999999;

  @media (max-width: 768px) {
    top: -32px;
    left: 0;
    right: auto;
    width: 21px;
    height: 21px;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 120px;
  right: 10px;
  width: auto;
  height: auto;
  background: transparent;
  animation: ${fadeIn} 0.6s ease;

  @media (max-width: 768px) {
    bottom: 90px;
    right: 10px;
    width: auto;
    height: auto;
    border: none;
    background: transparent;
    padding: 0;
  }
`;

export function RecoSales({ front, sales_messages }: Props) {
  // ランダムに表示するメッセージ
  const [showSalesTalk, setShowSalesTalk] = useState<SalesMessage | null>(null);
  // 表示するメッセージを切り替える
  useEffect(() => {
    const setTalk = () => {
      // salesTalkをランダムに選択する
      const salesTalk =
        sales_messages[Math.floor(Math.random() * sales_messages.length)];
      setShowSalesTalk(salesTalk);
    };
    setTalk();
    const timerId = setInterval(setTalk, 5000);

    return () => clearTimeout(timerId);
  }, [sales_messages]);

  // メッセージを表示するかどうか
  const [isShowMessage, setShowMessage] = useState<boolean>(true);
  const toggleMessage = useCallback(() => {
    setShowMessage((prev) => !prev);
  }, []);

  // メッセージにカーソルが乗っているかどうか
  const [isHovered, setIsHovered] = useState<boolean>(false);

  // メッセージのリンクをクリックしたときの処理
  const clickLink = useCallback(
    (link: string) => {
      // GAが設定されている場合はイベントを送信する
      if (typeof window.gtag === "function") {
        window.gtag("event", "reco_sales_click", {
          event_category: "sales",
          event_label: link,
          sales_talk: showSalesTalk?.message,
        });
      }
      if (typeof window.ga === "function") {
        window.ga("send", {
          hitType: "event",
          eventCategory: "myCustomEvent",
          eventAction: "myEventAction1",
          eventLabel: "myEventLabel1",
        });
      }
      window.open(link, "_self");
    },
    [showSalesTalk?.message],
  );

  return (
    <Container>
      {isShowMessage && showSalesTalk != null && (
        <BubbleBox
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <CloseButton onClick={toggleMessage}>
            <AiOutlineClose color="#666" />
          </CloseButton>

          <BubbleBoxInner
            onClick={() => clickLink(showSalesTalk.target_link ?? "#")}
          >
            <MesasgeContainer>
              <Message isHovered={isHovered}>{showSalesTalk.message}</Message>
              {showSalesTalk.caption && (
                <Caption isHovered={isHovered}>{showSalesTalk.caption}</Caption>
              )}
            </MesasgeContainer>
            <ActionLabel>
              {showSalesTalk.target_label}
              <ArrowButton isHovered={isHovered}>
                <BsArrowRightCircle />
              </ArrowButton>
            </ActionLabel>
          </BubbleBoxInner>
        </BubbleBox>
      )}
      {isShowMessage && (
        <ButtonContainer>
          <IconButtonLinks links={front} />
        </ButtonContainer>
      )}
      <CharaButton
        type="button"
        $isOpen={isShowMessage}
        onClick={toggleMessage}
      >
        <CharaContainer>
          <RecoCharacter normal={front.character} />
        </CharaContainer>
        <CharaBackground />
      </CharaButton>
    </Container>
  );
}
