import styled from "styled-components";

export type MenuButtonType = {
  id: string;
  element: JSX.Element;
  onClick: () => void;
};

export type Shape = "full" | "half" | "quarter" | "vertical";

export type Props = {
  /** 画像まわりのボタン */
  buttons: MenuButtonType[];
  /** ボタン表示タイプ */
  shape: Shape;
  /** 開始角度 */
  startAngleDegree: number;
  /** 内部のコンテンツ */
  children?: JSX.Element;
  /** 中心点のX座標 */
  cx?: number;
  /** 中心点のY座標 */
  cy?: number;
  /** 半径 */
  radius?: number;
  /** ポイント間の距離 */
  distanceBetween?: number;
  /** メニューリストを表示させるか */
  showmenu?: boolean;
};

type Point = {
  x: number;
  y: number;
};

/**
 * 円周上の座標を取得する
 * @param shape ボタン表示タイプ
 * @param startAngleDegree 開始角度
 * @param numPoints ポイント数
 * @param cx 中心点のX座標
 * @param cy 中心点のY座標
 * @param radius 半径
 * @returns 円周上の座標
 */
function getCirclePoints({
  shape,
  startAngleDegree,
  numPoints,
  cx,
  cy,
  radius,
}: {
  shape: Shape;
  startAngleDegree: number;
  numPoints: number;
  cx: number;
  cy: number;
  radius: number;
}): Point[] {
  let startAngle: number;
  let endAngle: number;
  let angleIncrement: number;

  // 度数法からラジアンに変換
  const startAngleRadian = (Math.PI / 180) * startAngleDegree;

  switch (shape) {
    case "full":
      startAngle = 0 + startAngleRadian;
      endAngle = 2 * Math.PI + startAngleRadian;
      angleIncrement = (endAngle - startAngle) / numPoints;
      break;
    case "half":
      startAngle = Math.PI / 2 + startAngleRadian;
      endAngle = (3 * Math.PI) / 2 + startAngleRadian;
      angleIncrement = (endAngle - startAngle) / (numPoints - 1);
      break;
    case "quarter":
      startAngle = Math.PI + startAngleRadian;
      endAngle = (3.2 * Math.PI) / 2 + startAngleRadian;
      angleIncrement = (endAngle - startAngle) / (numPoints - 1);
      break;
    default:
      throw new Error("Invalid shape");
  }

  const points: Point[] = Array.from({ length: numPoints }).map((_, i) => {
    const angle = startAngle + i * angleIncrement;
    const x = cx + radius * Math.cos(angle);
    const y = cy + radius * Math.sin(angle);
    return { x, y };
  });

  return points;
}

/**
 * 縦並びの座標を取得する
 * @param startX 開始点のX座標
 * @param startY 開始点のY座標
 * @param numPoints ポイント数
 * @param distanceBetween ポイント間の距離
 * @returns 縦並びの座標
 */
function generatePoints(
  startX: number,
  startY: number,
  numPoints: number,
  distanceBetween: number,
): Point[] {
  const points: Point[] = [];

  for (let i = 0; i < numPoints; i++) {
    points.push({
      x: startX,
      y: startY - distanceBetween * (i + 1),
    });
  }

  return points;
}

const Container = styled.div`
  position: relative;
  z-index: 0;
`;

interface ButtonType {
  showmenu: boolean;
  x: number;
  y: number;
}
const MenuButton = styled.button<ButtonType>`
  position: absolute;
  border: 0.1rem solid gray;
  background-color: white;
  padding: 0.3rem;
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  left: ${(props: ButtonType) => (props.showmenu ? `${props.x / 10}rem` : 0)};
  top: ${(props: ButtonType) => (props.showmenu ? `${props.y / 10}rem` : 0)};
  height: ${(props: ButtonType) => (props.showmenu ? "auto" : 0)};
  width: ${(props: ButtonType) => (props.showmenu ? "auto" : 0)};
  overflow: hidden;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  z-index: 1;
`;

const CenterContent = styled.div<{ cx: number; cy: number }>`
  position: absolute;
  top: ${(props) => `${props.cy / 10}rem`};
  left: ${(props) => `${props.cx / 10}rem`};
  transform: translate(-50%, -50%);
  z-index: 2;
`;

/**
 * 円形メニュー
 * @param buttons 画像まわりのボタン
 * @param shape ボタン表示タイプ
 * @param startAngleDegree 開始角度
 * @param children 内部のコンテンツ
 * @param cx 中心点のX座標
 * @param cy 中心点のY座標
 * @param radius 半径
 * @param distanceBetween ポイント間の距離
 * @param showMenu メニューリストを表示させるか
 * @returns 円形メニュー
 */
export function CircleMenu({
  buttons,
  shape,
  startAngleDegree,
  children,
  cx = 0,
  cy = 0,
  radius = 150,
  distanceBetween = 80,
  showmenu = false,
}: Props): JSX.Element {
  const numPoints = buttons.length;
  const points =
    shape === "vertical"
      ? generatePoints(cx, cy - radius, numPoints, distanceBetween)
      : getCirclePoints({
          shape,
          startAngleDegree,
          numPoints,
          cx,
          cy,
          radius,
        });

  return (
    <Container>
      {buttons.map((button, index) => (
        <MenuButton
          key={button.id}
          type="button"
          showmenu={showmenu}
          x={showmenu ? points[index].x : cx}
          y={showmenu ? points[index].y : cy}
          onClick={button.onClick}
        >
          {button.element}
        </MenuButton>
      ))}
      <CenterContent cx={cx} cy={cy}>
        {children}
      </CenterContent>
    </Container>
  );
}
