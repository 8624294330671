import * as Sentry from "@sentry/react";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { styled } from "styled-components";

import { RecoSalesCommand } from "@/commands/RecoSalesCommand";
import { MenuButtonType, Shape } from "@/components/molecules/CircleMenu";
import { CharacterCommand } from "@/components/organisms/CharacterCommand";
import { CommandComponent, loadCommand } from "@/config/Command";
import { Command, CustomerConfigService, OpenAPI } from "@/libs/client";
import {
  centerPositionAtom,
  characterMotionAtom,
  customerCodeAtom,
  customerConfigAtom,
  languageAtom,
} from "@/utils/atoms";

// テスト用CustomerConfig定義.全コマンドを利用可能にする
// VITE_CUSTOMER_CODE未設定時にこの定義を利用
/* eslint @typescript-eslint/no-explicit-any: 0 */
const testCustomerConfig = {
  customerCode: "TEST",
  origins: ["example.com"],
  commands: [
    {
      key: "siteSearch",
      options: {
        siteUrl: "https://duckduckgo.com/",
      } as Record<string, any>,
    },
    // { key: "review" },
    // { key: "translation" },
    { key: "recommend" },
    { key: "cart" },
    { key: "faq" },
    // { key: "account" },
    { key: "menu" },
  ] as Command[],
};

const testUluluCode = {
  customerCode: "test_ululu_code",
  origins: ["*"],
  commands: [{ key: "sales" }] as Command[],
};

const CommandContainer = styled.div<{ top: number; left: number }>`
  position: fixed;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  z-index: 9999999999;
  transition: all 0.3s ease;
`;

function App() {
  const [customerCode, setCustomerCode] = useAtom(customerCodeAtom);
  useEffect(() => {
    // VITE_CUSTOMER_CODEが設定されている場合はそちらを優先する
    if (import.meta.env.VITE_CUSTOMER_CODE !== undefined) {
      setCustomerCode(import.meta.env.VITE_CUSTOMER_CODE);
      return;
    }

    // ローダーからcustomerCodeを取得する
    const loader = document.getElementById("reco-loader");
    if (loader?.dataset.recoCustomerCode !== undefined) {
      setCustomerCode(loader.dataset.recoCustomerCode);
    }
  }, [setCustomerCode]);

  useEffect(() => {
    // へッダーにcustomerCodeを設定する
    if (customerCode !== "") {
      OpenAPI.HEADERS = { "customer-code": customerCode };
    }
  }, [customerCode]);

  // fetch customer config by customer code
  const [customerConfig, setCustomerConfig] = useAtom(customerConfigAtom);
  useEffect(() => {
    const fetchCustomerConfig = async () => {
      // CustomerCode未定義時は全てのCommandを表示
      if (customerCode === "") {
        setCustomerConfig(testCustomerConfig);
        return;
      }
      // CommandKeyが変更できないため暫定処理
      if (
        customerCode === "test_ululu_code" ||
        customerCode === "teradox_glam_print" ||
        customerCode === "fast_english"
      ) {
        setCustomerConfig(testUluluCode);
        return;
      }
      try {
        const appConfig =
          await CustomerConfigService.getCustomerConfigApiCustomerConfigGet(
            customerCode,
          );
        setCustomerConfig(appConfig);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchCustomerConfig();
  }, [customerCode, setCustomerConfig]);

  // キャラクターモーションセッター
  const setCharacterMotion = useSetAtom(characterMotionAtom);
  // 画面の中央をセットする
  const setCenterPosition = useSetAtom(centerPositionAtom);
  // 言語設定
  const setLanguage = useSetAtom(languageAtom);
  useEffect(() => {
    setLanguage(navigator.language);
  }, [setLanguage]);

  // コマンドメニューの形状
  const [menuShape, setMenuShape] = useState<Shape>("quarter");

  // キャラクターのポジション
  const [characterPosition, setCharacterPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  // ウィンドウのサイズ
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setCenterPosition({
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [setCenterPosition]);

  // きゃらくたーを画面端に移動させるuseCallback関数
  const moveCharacterToEdge = useCallback(() => {
    setCharacterPosition({
      x: windowSize.width - 100,
      y: windowSize.height - 100,
    });
    setMenuShape("quarter");
  }, [windowSize]);

  // キャラクターを画面中央に表示するuseCallback関数
  const centerCharacter = useCallback(() => {
    setCharacterPosition({
      x: windowSize.width / 2,
      y: windowSize.height / 2,
    });
    setMenuShape("full");
  }, [windowSize]);

  // 初期表示時にキャラクターを画面端に移動させる
  useEffect(() => {
    moveCharacterToEdge();
  }, [moveCharacterToEdge]);

  // 選択中のコマンド
  const [selectedCommand, setSelectedCommand] = useState<string | null>(null);
  // コマンドを選択したときの処理
  const selectCommand = useCallback(
    (commandId: string) => {
      setCharacterMotion("normal");
      setSelectedCommand(commandId);
      if (commandId === "recommend") {
        centerCharacter();
      }
    },
    [centerCharacter, setCharacterMotion],
  );

  // キャラクターのコマンドを閉じるボタン
  const closeButton = {
    id: "close",
    element: (
      <AiOutlineClose size={24} className="text-gray-500 hover:text-gray-700" />
    ),
    onClick: () => {
      setSelectedCommand(null);
      moveCharacterToEdge();
    },
  };
  const [commandList, setComnandList] = useState<CommandComponent[]>([]);
  const [menuButtons, setMenuButtons] = useState<MenuButtonType[]>([]);

  // build command list
  useEffect(() => {
    const buildCommand = async () => {
      const cmdList = (
        await Promise.all(
          customerConfig.commands.map(async (cmdDef) => {
            const cmd = await loadCommand(cmdDef.key, cmdDef.options);
            return cmd;
          }),
        )
      ).filter((c): c is Exclude<typeof c, null> => c !== null);

      setComnandList(cmdList);

      // メニューボタンのリスト
      const lst = cmdList.map((command) => ({
        id: command.id,
        element: command.menuButton,
        onClick: () => {
          selectCommand(command.id);
          setMenuShape("vertical");
        },
      }));
      setMenuButtons(lst);
    };
    buildCommand();
  }, [customerConfig, selectCommand, setComnandList]);

  // 表示するコマンドコンポーネント
  const selectedCommandComponent = useMemo(() => {
    // console.debug("selectedcmd", commandList);
    const command = commandList.find((item) => item.id === selectedCommand);
    if (command == null) {
      return null;
    }
    return command.component;
  }, [selectedCommand, commandList]);

  if (customerConfig.commands.length === 0) {
    return null;
  }

  if (customerConfig.commands[0].key === "sales") {
    return (
      <CommandContainer top={characterPosition.y} left={characterPosition.x}>
        <RecoSalesCommand />
      </CommandContainer>
    );
  }

  return (
    <CommandContainer top={characterPosition.y} left={characterPosition.x}>
      {selectedCommandComponent != null && (
        <div className="relative">{selectedCommandComponent}</div>
      )}
      <CharacterCommand
        buttons={selectedCommand != null ? [closeButton] : menuButtons}
        shape={menuShape}
        startAngleDegree={menuShape === "full" ? -90 : 0}
      />
    </CommandContainer>
  );
}

export default App;
