import { useAtomValue } from "jotai";
import { useMemo } from "react";
import styled from "styled-components";

import { Image } from "@/components/atoms/Image";
import { characterMotionAtom } from "@/utils/atoms";

const Container = styled.div`
  width: 6rem;
`;

const StyledImage = styled(Image)`
  width: 100%;
`;

type Props = {
  move?: string;
  attention?: string;
  happy?: string;
  normal: string;
};

export function RecoCharacter({
  normal,
  move,
  attention,
  happy,
}: Props): JSX.Element {
  const characterMotion = useAtomValue(characterMotionAtom);
  const imageComponent = useMemo(() => {
    if (characterMotion === "move") {
      return <StyledImage src={move ?? normal} alt="reco" />;
    }

    if (characterMotion === "attention") {
      return <StyledImage src={attention ?? normal} alt="reco" />;
    }

    if (characterMotion === "happy") {
      return <StyledImage src={happy ?? normal} alt="reco" />;
    }

    return <StyledImage src={normal} alt="reco" />;
  }, [attention, characterMotion, happy, move, normal]);

  return <Container>{imageComponent}</Container>;
}
