import {
  AiOutlineMenu,
  AiOutlineShop,
  AiOutlineShoppingCart,
  AiOutlineComment,
  AiOutlineTranslation,
} from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { BsFillPersonFill, BsQuestion } from "react-icons/bs";

export interface CommandComponent {
  id: string;
  menuButton: JSX.Element;
  component: JSX.Element;
}

/**
 * idで指定したコマンドのコンポーネントをdynamic importして、Command型で返す.
 * 存在しないidを指定した場合はnull
 */
export const loadCommand = async (
  key: string,
  options: Record<string, any> | null = null,
): Promise<CommandComponent | null> => {
  switch (key) {
    case "siteSearch": {
      const { SiteSearches } = await import("@/commands/SiteSearches");
      // TODO: optionをcommandに渡す
      if (options !== null) {
        // optionを渡すののにcomponent側の改修とbackend側の改修両方が必要なので一旦残したままにします
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { siteUrl } = options;
      }
      return {
        id: key,
        menuButton: (
          <BiSearch size={24} className="text-gray-500 hover:text-gray-700" />
        ),
        component: <SiteSearches />,
      };
    }
    case "review": {
      const { Reviews } = await import("@/commands/Reviews");
      return {
        id: key,
        menuButton: (
          <AiOutlineComment
            size={24}
            className="text-gray-500 hover:text-gray-700"
          />
        ),
        component: <Reviews />,
      };
    }
    case "translation": {
      const { Translations } = await import("@/commands/Translations");
      return {
        id: key,
        menuButton: (
          <AiOutlineTranslation
            size={24}
            className="text-gray-500 hover:text-gray-700"
          />
        ),
        component: <Translations />,
      };
    }
    case "recommend": {
      const Recoomend = (await import("@/commands/Recommend")).Recommend;
      return {
        id: key,
        menuButton: (
          <AiOutlineShop
            size={24}
            className="text-gray-500 hover:text-gray-700"
          />
        ),
        component: <Recoomend />,
      };
    }
    case "cart": {
      return {
        id: key,
        menuButton: (
          <AiOutlineShoppingCart
            size={24}
            className="text-gray-500 hover:text-gray-700"
          />
        ),
        component: <div>cart</div>,
      };
    }
    case "faq": {
      const { Subsidy } = await import("@/commands/Subsidy");
      return {
        id: key,
        menuButton: (
          <BsQuestion size={24} className="text-gray-500 hover:text-gray-700" />
        ),
        component: <Subsidy />,
      };
    }
    case "account": {
      return {
        id: "account",
        menuButton: (
          <BsFillPersonFill
            size={24}
            className="text-gray-500 hover:text-gray-700"
          />
        ),
        component: <div>account</div>,
      };
    }
    case "menu": {
      return {
        id: key,
        menuButton: (
          <AiOutlineMenu
            size={24}
            className="text-gray-500 hover:text-gray-700"
          />
        ),
        component: <div>menu</div>,
      };
    }
    default:
      break;
  }

  return null;
};
