import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { RecoCharacter } from "@/components/atoms/RecoCharacter";
import {
  CircleMenu,
  Shape,
  MenuButtonType,
} from "@/components/molecules/CircleMenu";
import { characterMotionAtom } from "@/utils/atoms";

type Props = {
  buttons: MenuButtonType[];
  shape: Shape;
  startAngleDegree?: number;
};

const Container = styled.div`
  width: 7rem;
`;

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
`;

export function CharacterCommand({ buttons, shape, startAngleDegree }: Props) {
  const [isOpenCommand, setOpenCommand] = useState<boolean>(false);
  const setCharacterMotion = useSetAtom(characterMotionAtom);
  useEffect(() => {
    setCharacterMotion(isOpenCommand ? "attention" : "normal");
  }, [isOpenCommand, setCharacterMotion]);

  return (
    <Container>
      <CircleMenu
        buttons={buttons}
        shape={shape}
        startAngleDegree={startAngleDegree ?? 0}
        cx={0}
        cy={0}
        distanceBetween={40}
        radius={shape === "vertical" ? 60 : 90}
        showmenu={isOpenCommand}
      >
        <StyledButton
          type="button"
          onClick={() => setOpenCommand((prev) => !prev)}
        >
          <RecoCharacter
            normal="/charactors/fanyao_00.gif"
            move="/charactors/fanyao_01.gif"
            attention="/charactors/fanyao_02.gif"
            happy="/charactors/fanyao_03.gif"
          />
        </StyledButton>
      </CircleMenu>
    </Container>
  );
}
