// eslint-disable-next-line import/no-extraneous-dependencies
import "@/index.css";
// import "@/reset.css";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "@/App";
import { OpenAPI } from "@/libs/client";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_URL,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

OpenAPI.BASE = import.meta.env.VITE_OPEN_API_URL;

ReactDOM.createRoot(
  document.getElementById("reco-app-root") as HTMLElement,
).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
