import * as Sentry from "@sentry/react";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

import { Front, SalesMessage } from "@/commands/RecoSalesCommand/schema";
import { RecoSales } from "@/components/organisms/RecoSales";
import { customerCodeAtom } from "@/utils/atoms";
// import { FrontService, SalesTalkService } from "@/libs/client";

const commandData: {
  [key: string]: {
    front: Front;
    salesTalk: { sales_messages: SalesMessage[] };
  };
} = {
  fast_english: {
    front: {
      id: "demo",
      theme_color: "#FF0000",
      character: `${import.meta.env.BASE_URL}ululu/ululu_blue/02_ululu_BL.gif`,
      display_url: "https://reco.ai/",
      line: "",
      facebook: "",
      instagram: "",
      x: "",
      tiktok: "",
      inquiry_url: "",
      faq_url: "",
    },
    salesTalk: {
      sales_messages: [
        {
          id: "1566fad2-81ae-401f-b22f-cc22254cbba4",
          message: "あなたもFAST ENGLISHと一緒に英語を楽しく飛ばすぜ！",
          caption:
            "7日間無料体験、通い放題で月額9,980円！楽しく英会話にトライしよう",
          target_label: "7日間の無料体験に申し込む",
          target_link: "https://fast-english.jp/#form",
        },
        {
          id: "1566fad2-81ae-401f-b22f-cc22254cbba4",
          message: "7日間無料、あなたも英会話上達の風を感じてみませんか？",
          caption:
            "FAST ENGLISH – 学び放題、通い放題。あなたの英会話学習のパートナーとして、成長と楽しさを共有しましょう！",
          target_label: "7日間の無料体験に申し込む",
          target_link: "https://fast-english.jp/#form",
        },
        {
          id: "1566fad2-81ae-401f-b22f-cc22254cbba4",
          message: "あなたの英語、スピードアップ！FAST ENGLISHで無料体験！",
          caption: "7日間で、自分の英語力に驚きを！今なら無料体験実施中",
          target_label: "7日間の無料体験に申し込む",
          target_link: "https://fast-english.jp/#form",
        },
        {
          id: "1566fad2-81ae-401f-b22f-cc22254cbba4",
          message: "英会話成果、みせます！FAST ENGLISHで7日間無料体験！",
          caption:
            "通い放題★スパルタ式★7日無料体験！いつでもどこでも英会話力アップ、今すぐスタート!",
          target_label: "7日間の無料体験に申し込む",
          target_link: "https://fast-english.jp/#form",
        },
        {
          id: "1566fad2-81ae-401f-b22f-cc22254cbba4",
          message: "英会話、あなたの自由に。FAST ENGLISH",
          caption:
            "いつでもどこでも通い放題英会話スクール。7日間無料体験で異次元の学習体験を！",
          target_label: "7日間の無料体験に申し込む",
          target_link: "https://fast-english.jp/#form",
        },
      ],
    },
  },
  teradox_glam_print: {
    front: {
      id: "demo2",
      theme_color: "#FF0000",
      character: `${import.meta.env.BASE_URL}ululu/ululu_blue/03_ululu_BL.gif`,
      display_url: "https://glam-print.com",
      line: "https://lin.ee/ZxlSa4bD",
      facebook: "",
      instagram: "",
      x: "",
      tiktok: "",
      inquiry_url:
        "https://mypage.glam-print.com/index.php?action_site_support=true",
      faq_url: "https://glam-print.com/faq",
    },
    salesTalk: {
      sales_messages: [
        {
          id: "db85f21a-7ec9-4d65-8631-57929c611109",
          message: "2025年巳年もLINE限定のお得なクーポンを配信予定です♪",
          caption: "GLAM PRINTの公式LINE！",
          target_label: "今すぐ友だち追加する",
          target_link: "https://lin.ee/ZxlSa4bD",
        },
      ],
    },
  },
};

export function RecoSalesCommand() {
  const customerCode = useAtomValue(customerCodeAtom);
  const [front, setFront] = useState<Front | null>(null);
  const [salesMessages, setSalesMessages] = useState<SalesMessage[]>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        // const frontData = await FrontService.frontsApiFrontsGet();
        // setFront(frontData);
        // const salesTalk = await SalesTalkService.getSalesTalkApiSalesTalkGet();
        // setSalesMessages(salesTalk.sales_messages);

        const { front: frontData } = commandData[customerCode];
        setFront(frontData);
        const { salesTalk } = commandData[customerCode];
        setSalesMessages(salesTalk.sales_messages);
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    fetch();
  }, [customerCode]);

  return (
    <div>
      {front && <RecoSales front={front} sales_messages={salesMessages} />}
    </div>
  );
}
