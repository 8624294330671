/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerConfigResponseSchema } from '../models/CustomerConfigResponseSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerConfigService {

    /**
     * Get Customer Config
     * 顧客のconfig情報を返す。
     *
     * parameters:
     *
     * customer_code: 顧客がそれぞれ持つ一意の文字列
     * - type string
     *
     * return:
     *
     * 顧客が持つconfig情報
     * @param customerCode
     * @returns CustomerConfigResponseSchema Successful Response
     * @throws ApiError
     */
    public static getCustomerConfigApiCustomerConfigGet(
        customerCode: string,
    ): CancelablePromise<CustomerConfigResponseSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/customer-config/',
            query: {
                'customer_code': customerCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
