import { forwardRef } from "react";

export const Image = forwardRef<
  HTMLImageElement,
  React.ImgHTMLAttributes<HTMLImageElement>
>(({ src, alt, ...props }, ref) => {
  if (src === undefined) {
    return null;
  }

  const url = import.meta.env.VITE_IS_CHROME_EXTENSION
    ? chrome.runtime.getURL(src)
    : src;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <img ref={ref} src={url} alt={alt} {...props} />
  );
});
