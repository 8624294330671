import { useMemo } from "react";
import { AiOutlineInstagram, AiOutlineQuestionCircle } from "react-icons/ai";
import { FaTiktok, FaFacebookF } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { RiTwitterXLine } from "react-icons/ri";
import styled from "styled-components";

import LineIconPath from "@/assets/images/line_circle.png";

export type IconButtonLink = {
  id: string;
  element: JSX.Element;
  linkUrl: string;
};

type Props = {
  links: {
    line: string | null;
    facebook: string | null;
    instagram: string | null;
    x: string | null;
    tiktok: string | null;
    inquiry_url: string | null;
    faq_url: string | null;
  };
};

const LineIcon = styled.img.attrs({
  src: LineIconPath,
  alt: "line",
})`
  width: 100%;
  height: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LinkButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  background: linear-gradient(45deg, #21a8d2 14.15%, #21dbd2 85.01%);
  color: #fff;
  font-size: 24px;

  &:hover {
    background: linear-gradient(45deg, #21a8d2 14.15%, #21dbd2 85.01%);
    color: #fff;
  }
`;

export function IconButtonLinks({ links }: Props) {
  const iconButtonLinks = useMemo<IconButtonLink[]>(() => {
    const buttonLinks: IconButtonLink[] = [];

    if (links.line) {
      buttonLinks.push({
        id: "line",
        element: <LineIcon />,
        linkUrl: links.line,
      });
    }

    if (links.facebook) {
      buttonLinks.push({
        id: "facebook",
        element: <FaFacebookF />,
        linkUrl: links.facebook,
      });
    }

    if (links.instagram) {
      buttonLinks.push({
        id: "instagram",
        element: <AiOutlineInstagram />,
        linkUrl: links.instagram,
      });
    }

    if (links.x) {
      buttonLinks.push({
        id: "x",
        element: <RiTwitterXLine />,
        linkUrl: links.x,
      });
    }

    if (links.tiktok) {
      buttonLinks.push({
        id: "tiktok",
        element: <FaTiktok />,
        linkUrl: links.tiktok,
      });
    }

    if (links.inquiry_url) {
      buttonLinks.push({
        id: "inquiry",
        element: <FiMail />,
        linkUrl: links.inquiry_url,
      });
    }

    if (links.faq_url) {
      buttonLinks.push({
        id: "faq",
        element: <AiOutlineQuestionCircle />,
        linkUrl: links.faq_url,
      });
    }

    return buttonLinks;
  }, [links]);

  return (
    <Container>
      {iconButtonLinks.map((button) => (
        <LinkButton key={button.id} href={button.linkUrl}>
          {button.element}
        </LinkButton>
      ))}
    </Container>
  );
}
